.project-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
}

.project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem;
}

/* Media Query */

@media (max-width: 1380px) {
  .project-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .project-heading {
    font-size: 30px;
    text-align: center;
  }

  .project-cards {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
