.main-Button {
  background-color: #25c2a0;
  border: solid 1px #25c2a0;
  color: white;
  font-weight: 700;
  width: max-content;
  padding: 13px 22px;
  margin-right: 50px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
}

.main-Button:hover {
  background-color: #fff;
  color: black;
  transition: ease-in 0.3s;
}

/* Media Query */

@media (max-width: 768px) {
  .main-Button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 0px;
  }

  .project-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media (max-width: 320px) {
  .main-Button {
    font-size: 12px;
  }

  .project-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
