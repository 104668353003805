.social-media {
  font-size: 2rem;
}

.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  color: white;
  border-radius: 2.6rem;
  cursor: pointer;
  /* display: inline-block; */
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0px 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.github i {
  background-color: #333;
}

.linkedin i {
  background-color: #0e76a8;
}

.twitter i {
  background-color: #1da1f2;
}

.twitter i:hover,
.github i:hover,
.linkedin i:hover {
  background-color: #333;
  transition: 0.3s ease-in;
}

/* Media Query */
@media (max-width: 768px) {
  .social-media {
    text-align: center;
  }
}
